import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import chunk from 'lodash/chunk'
import difference from 'lodash/difference'

// Base Page
import BasePage from '@/pages/Base'

// Services
import ProvinceService from '@/services/Province'
import CountryService from '@/services/Country'
import SettingsService from '@/services/GlobalSettings'
import RulesService from '@/services/Rules'
import CityService from '@/services/City'
import PaymentChannelsService from '@/services/PaymentChannels'
import StatusService from '@/services/Status'
import { data } from 'jquery'

@Component({})
export default class CountryList extends BasePage {
  constructor() {
    super()
  }
  //Default Weight
  itemsWeight: any[] = ['kg', 'lb']
  itemsDimension: any[] = ['in', 'cm']

  //method transit point
  getCityLoading: boolean = false
  getProvinceLoading: boolean = false
  itemOriginName: any = []
  itemOriginKeywords: any = null
  searchOriginDebounce: Function
  getOneCityLoading: boolean = false
  getOneProvinceLoading: boolean = false

  listProductsDialog: boolean = false
  listProductsObject: any = {}
  id_user: number = 0
  allTotalProducts: number = 0
  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'name',
    page: 1,
    rowsPerPage: 25,
    descending: false,
    totalItems: 0
  }
  tableLoading: boolean = true

  countryDialog: boolean = false
  isEdit: boolean = false
  countryDataDialog: any = {}
  categories: any = {
    items: [],
    selected: [],
    keyword: null,
    loading: false
  }
  searchDebounce: Function
  currentCategories: any[] = []

  selectedDelete: any = {}
  selectedIdCountry: number = 0
  showDeleteModal: boolean = false

  selectedCountry: string = ''

  searchCountries: Function
  searchFilter: Function

  itemsTransitmethod: any[] = ['City', 'Province'];

  // Country Default Payment (Payment Channel)
  internationalPayment: any = {
    payment_channel_ids: [],
    instruction: null,
    status_id: null
  }
  domesticPayment: any = {
    payment_channel_ids: [],
    instruction: null,
    status_id: null
  }
  remarkStatusList: any = []
  defaultPaymentCountryModal: boolean = false
  countrySelected: any = {}
  paymentChannelsList: any = []

  headerCategoryRules: object[] = [
    {
      text: 'Description',
      align: 'left',
      sortable: false,
      value: 'description'
    }
  ]
  itemCategoryRules: object[] = []
  totalCategoryRules: string | number = 0
  rowsPerPageCategoryRules: number[] = [5]
  paginationCategoryRules: any = {
    sortBy: 'description',
    page: 1,
    rowsPerPage: 5,
    descending: true,
    totalItems: 0
  }
  loadingCategoryRules: boolean = true
  selectCategoryRules: any = []
  searchCategoryRules: string = ''
  selectedRulesID: any = []
  checkboxAllSelected: boolean = false
  dataRulesAllSelected: any = {}

  async mounted() {
    this.searchDebounce = await debounce(this.getCategories, 500)
    this.searchOriginDebounce = debounce(this.getOriginName, 500)
    this.getPaymentChannelsList()
    this.getRemarkStatus()
  }

  @Watch('itemOriginKeywords')
  onitemNameChange() {
    this.searchOriginDebounce()
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getCountries()
  }

  // @Watch('paginationCategoryRules', { deep: true })
  async onChangedCategoryRules() {
    this.$vuetify.goTo(0)
    this.getSelectedCategories()
  }

  @Watch('paginationCategoryRules', { deep: true })
  async onNextPagination() {
    this.selectedRulesID = []
    this.checkboxAllSelected = false
  }

  // @Watch('categories.keyword')
  async onChangedCategories() {
    await this.searchDebounce()
  }

  async onClearSearch() {
    this.searchInput = ''
    this.getCountries()
  }

  async getCountries() {
    try {
      this.tableLoading = true
      const opts = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
          'filter[name][like]': ''
        }
      }
      if (this.searchInput != '') {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.searchInput
        }
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      const responseMeta = response.data.meta
      this.tableItems = []
      forEach(responseData, dataCountry => {
        this.tableItems.push(dataCountry.attributes)
      })
      this.tableTotalItems = responseMeta.pagination.total
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  // openCreateDialog() {
  //   this.isEdit = false
  //   this.countryDataDialog = {
  //     name: null,
  //     country_id: null
  //   }
  //   this.provinceDialog = true
  //   this.getCountries()
  // }

  closeDialog() {
    this.countryDialog = false
    this.selectedRulesID = []
    this.checkboxAllSelected = false
    this.searchCategoryRules = ''
  }

  // async addProvince() {
  //   try {
  //     this.showLoading({ text: 'Saving...' })
  //     this.provinceDialog = false
  //     const payload = {
  //       name: this.provinceDataDialog.name,
  //       country_id: this.provinceDataDialog.country_id
  //     }
  //     await ProvinceService.setProvinces(payload);
  //     this.showSnackbar({
  //       text: 'Saved Successfully!',
  //       color: 'green',
  //       timeout: 1500
  //     })
  //     this.getProvinces()
  //   } catch (error) {
  //     this.catchHandler(error)
  //   } finally {
  //     this.closeLoading()
  //   }
  // }

  // openDeleteModal(props) {
  //   this.selectedDelete = props.item
  //   this.showDeleteModal = true
  // }

  // async deleteProvince() {
  //   try {
  //     this.showLoading({ text: 'Saving...' })
  //     this.showDeleteModal = false
  //     const response = await ProvinceService.deleteProvince(this.selectedDelete.id)
  //     this.showSnackbar({
  //       text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
  //       color: response.status === 200 ? 'green' : 'red',
  //       timeout: 1500
  //     })
  //     this.getProvinces()
  //   } catch (error) {
  //     this.catchHandler(error)
  //   } finally {
  //     this.closeLoading()
  //   }
  // }

  async openEditDialog(props) {
    // this.isEdit = true
    this.categories.keyword = null
    this.selectedIdCountry = props.item.id
    this.countryDataDialog = {
      id: props.item.id,
      name: props.item.name,
      currency: props.item.currency,
      weight_unit: isEmpty(props.item.weight_unit) ? 'kg' : props.item.weight_unit,
      dimension_unit: isEmpty(props.item.dimension_unit) ? 'cm' : props.item.dimension_unit,
      groupBuy: false
    }

    const deminimis = await this.getDeminimis(props.item.id)

    this.countryDataDialog = {
      ...this.countryDataDialog,
      id_deminimis_currency: isEmpty(deminimis[0]) ? null : deminimis[0].attributes.id,
      deminimis_currency: isEmpty(deminimis[0]) ? null : deminimis[0].attributes.value,

      id_deminimis_limit: isEmpty(deminimis[1]) ? null : deminimis[1].attributes.id,
      deminimis_limit: isEmpty(deminimis[1]) ? [] : deminimis[1].attributes.value,

      id_shipping_term: isEmpty(deminimis[2]) ? null : deminimis[2].attributes.id,
      shipping_term: isEmpty(deminimis[2]) ? null : deminimis[2].attributes.value,

      id_exclude_deminimis_category: isEmpty(deminimis[3]) ? null : deminimis[3].attributes.id,
      // exclude_deminimis_category: isEmpty(deminimis[3]) ? null : JSON.parse(deminimis[3].attributes.value),
      exclude_deminimis_category: null,

      id_transit_point: isEmpty(deminimis[4]) ? null : deminimis[4].attributes.id,
      transit_point: isEmpty(deminimis[4]) ? null : JSON.parse(deminimis[4].attributes.value),
      transit_point_type: isEmpty(deminimis[4]) ? null : startCase(JSON.parse(deminimis[4].attributes.value).type),
      transit_point_id: isEmpty(deminimis[4]) ? null : JSON.parse(deminimis[4].attributes.value).id,

      id_handling_charges: isEmpty(deminimis[5]) ? null : deminimis[5].attributes.id,
      handling_charges: isEmpty(deminimis[5]) ? 0 : deminimis[5].attributes.value

    }

    this.countryDataDialog.length_exclude_deminimis_category =
      this.countryDataDialog.exclude_deminimis_category ? this.countryDataDialog.exclude_deminimis_category.length : 0

    var transit_point_name = ''
    if (!isEmpty(deminimis[4])) {
      if (lowerCase(JSON.parse(deminimis[4].attributes.value).type) === 'city') {
        transit_point_name = await this.getOneCities(this.countryDataDialog.transit_point_id)
      }
    }

    if (!isEmpty(deminimis[4])) {
      if (lowerCase(JSON.parse(deminimis[4].attributes.value).type) === 'province') {
        transit_point_name = await this.getOneProvinces(this.countryDataDialog.transit_point_id)
      }
    }

    // this.currentCategories = []
    // await this.getCategories(this.countryDataDialog.exclude_deminimis_category)
    // if (!isEmpty(this.countryDataDialog.exclude_deminimis_category)) {
    //   this.currentCategories = this.categories.items
    //   this.countryDataDialog.exclude_deminimis_category = this.currentCategories
    // }

    this.countryDialog = true

    // get deminimis category rules exclude
    this.currentCategories = isEmpty(deminimis[3]) ? [] : JSON.parse(deminimis[3].attributes.value)
    this.selectCategoryRules = []
    await this.getSelectedCategories()
    await this.getCategories()

    this.itemOriginName.push({
      name: transit_point_name,
      id: this.countryDataDialog.transit_point_id
    })
  }

  async getDeminimis(country_id) {
    const optsSettings: any = {
      params: {
        'filter[country_id][in]': country_id,
        'filter[source_country_id][in]': 'null',
        'page[limit]': 100
      }
    }
    const responseSettings = await SettingsService.getSettings(optsSettings)
    const responseDataSettings = responseSettings.data.data
    const deminimis_currency = find(responseDataSettings, setting => setting.attributes.key === 'deminimis_currency')
    const deminimis_limit = find(responseDataSettings, setting => setting.attributes.key === 'deminimis_limit')
    const transit_point = find(responseDataSettings, setting => setting.attributes.key === 'transit_point')

    if (typeof deminimis_limit !== 'undefined') {
      deminimis_limit.attributes.value = JSON.parse(deminimis_limit.attributes.value)
    }
    const shipping_term = find(responseDataSettings, setting => setting.attributes.key === 'shipping_term')
    const exclude_deminimis_category = find(responseDataSettings, setting => setting.attributes.key === 'exclude_deminimis_category' && setting.attributes.source_country_id === null)
    const handling_charges = find(responseDataSettings, setting => setting.attributes.key === 'handling_charges')
    return [deminimis_currency, deminimis_limit, shipping_term, exclude_deminimis_category, transit_point, handling_charges]
  }

  onDeminimisDeleted(index) {
    this.countryDataDialog.deminimis_limit.splice(index, 1)
  }

  onAddNewDeminimis() {
    const newLevelDeminimis = {
      value: 0,
      duty: 0,
      wht: 0,
      vat: 0
    }
    this.countryDataDialog.deminimis_limit.push(newLevelDeminimis)
  }

  onSelectCategory() {
    this.selectCategoryRules = this.countryDataDialog.exclude_deminimis_category
  }

  async getOneCities(id) {
    this.getOneCityLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CityService.getOneCities(id, opts)
      const responseData = response.data.data
      const citiesName = responseData.attributes.name
      return citiesName
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getOneCityLoading = false
    }
  }

  async getOneProvinces(id) {
    this.getOneProvinceLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await ProvinceService.getOneProvinces(id, opts)
      const responseData = response.data.data
      const provinceName = responseData.attributes.name
      return provinceName
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getOneProvinceLoading = false
    }
  }

  async getMethodProvinces() {
    this.getProvinceLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[country_id][is]': this.countryDataDialog.id
        }
      }
      if (!isEmpty(this.itemOriginKeywords)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.itemOriginKeywords
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await ProvinceService.getProvinces(opts)
      const responseData = response.data.data
      this.itemOriginName = []
      for (const province of responseData) {
        // this.itemOriginName.push(province.attributes)
        this.itemOriginName.push({
          name: province.attributes.name,
          id: province.attributes.id
        })
      }
      this.itemOriginName = sortBy(this.itemOriginName, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getProvinceLoading = false
    }
  }

  async getMethodCities() {
    this.getCityLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[country_id][is]': this.countryDataDialog.id
        }
      }
      if (!isEmpty(this.itemOriginKeywords)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.itemOriginKeywords
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await CityService.getCities(opts)
      const responseData = response.data.data
      this.itemOriginName = []
      for (const city of responseData) {
        // this.itemOriginName.push(city.attributes)
        this.itemOriginName.push({
          name: city.attributes.name,
          id: city.attributes.id
        })
      }
      this.itemOriginName = sortBy(this.itemOriginName, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCityLoading = false
    }
  }

  getOriginName() {
    if (lowerCase(this.countryDataDialog.transit_point_type) == 'cities' || lowerCase(this.countryDataDialog.transit_point_type) == 'city') {
      this.getMethodCities()
    } else if (lowerCase(this.countryDataDialog.transit_point_type) == 'provinces' || lowerCase(this.countryDataDialog.transit_point_type) == 'province') {
      this.getMethodProvinces()
    }
  }

  async clearDeminimisSearch() {
    this.categories.keyword = null
    await this.getCategories()
  }

  async getCategories(id = [], index = 0) {
    this.categories.loading = true
    try {
      const opts: any = {
        // set to dynamic
        params: {
          'page[num]': 1,
          'page[limit]': 50,
          'filter[description][like]': '',
          'filter[id][in]': !isEmpty(id) ? id.toString() : null
        }
      }
      if (!isEmpty(this.categories.keyword)) {
        var textFilter = 'filter[description][like]'
        opts.params[textFilter] = this.categories.keyword
        const responseTotal = await RulesService.getCategoryRules(opts)
        var textLimit = 'page[limit]'
        opts.params[textLimit] = responseTotal.data.meta.pagination.total
      } else {
        var textFilter = 'filter[description][like]'
        delete opts.params[textFilter]
      }
      const response = await RulesService.getCategoryRules(opts)
      const responseData = response.data.data
      this.categories.items = []
      if (!isEmpty(this.selectCategoryRules)) {
        for (const categoriesSelected of this.selectCategoryRules) {
          this.categories.items.push(categoriesSelected)
        }
      }

      for (const dataCategories of responseData) {
        // cek apabila id category rules sudah terpilih maka tidak akan tampil di item select category rules (agar id yang di-input tidak kembar)
        var check = false
        for (const c of this.currentCategories) {
          if (dataCategories.attributes.id == c) {
            check = true
            break
          }
        }

        if (!check) {
          this.categories.items.push(dataCategories.attributes)
        }
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.categories.loading = false
    }
  }

  async getCategoryRulesById(id) {
    try {
      const opts: any = {
        // set to dynamic
        params: {
          'page[num]': 1,
          'page[limit]': this.paginationCategoryRules.rowsPerPage,
          'filter[id][in]': id.toString()
        }
      }

      const response = await RulesService.getCategoryRules(opts)
      const responseData = response.data.data
      return responseData
    } catch (error) {
      this.catchHandler(error)
      return null
    }
  }

  async getSelectedCategories() {
    try {
      this.loadingCategoryRules = true
      var chunkCategoryRules = chunk(this.currentCategories, this.paginationCategoryRules.rowsPerPage)
      this.itemCategoryRules = []
      await forEach(chunkCategoryRules, async ids => {
        const response = await this.getCategoryRulesById(ids)
        if (response) {
          await forEach(response, dataCategories => {
            this.itemCategoryRules.push(dataCategories.attributes)
          })
        }
        this.selectedRulesID = []
        this.checkboxAllSelected = false
      })
      this.totalCategoryRules = this.currentCategories.length
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.loadingCategoryRules = false
    }
  }

  toggleAll() {
    if (this.checkboxAllSelected === true) {
      this.selectedRulesID = []
      forEach(this.itemCategoryRules, rule => {
        this.dataRulesAllSelected = rule
        this.selectedRulesID.push(this.dataRulesAllSelected.id)
      })
    } else {
      this.selectedRulesID = []
    }
  }

  changeSort(column) {
    if (this.paginationCategoryRules.sortBy == column) {
      if (this.paginationCategoryRules.descending == true) {
        this.paginationCategoryRules.sortBy_payload = column.replace('-', '')
        this.paginationCategoryRules.descending = !this.paginationCategoryRules.descending
      } else {
        this.paginationCategoryRules.sortBy_payload = '-' + column
        this.paginationCategoryRules.descending = !this.paginationCategoryRules.descending
      }
    } else {
      if (column != undefined) {
        this.paginationCategoryRules.sortBy = column
        this.paginationCategoryRules.sortBy_payload = '-' + column
        this.paginationCategoryRules.descending = true
      }
    }
  }

  async deleteCategoryRules() {
    try {
      this.showDeleteModal = false
      this.showLoading({ text: 'Saving...' })
      const payload_edit = {
        data: []
      }
      const new_ids = difference(this.currentCategories, this.selectedRulesID)

      var set_item_update = {
        id: this.countryDataDialog['id_exclude_deminimis_category'],
        attributes: {
          key: 'exclude_deminimis_category',
          country_id: this.countryDataDialog.id,
          source_country_id: null,
          value: JSON.stringify(new_ids)
        }
      }
      payload_edit.data.push(set_item_update)

      const responseEdit = await SettingsService.updateSettings(payload_edit)
      this.showSnackbar({
        text: 'Deleted Successfully!',
        color: 'green',
        timeout: 1500
      })
      this.currentCategories = JSON.parse(responseEdit.data.data[0].attributes.value)
      this.checkboxAllSelected = false
      this.selectedRulesID = []
      await this.getSelectedCategories()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async updateCountry() {
    const validationResponse = await this.$validator.validateAll('countryDataDialog')
    if (validationResponse) {
      try {
        this.showLoading({ text: 'Saving...' })
        this.countryDialog = false
        const payload = {
          currency: this.countryDataDialog.currency,
          weight_unit: this.countryDataDialog.weight_unit,
          dimension_unit: this.countryDataDialog.dimension_unit
        }
        await CountryService.updateCountry(this.selectedIdCountry, payload)
        await this.updateDeminimis()
        this.showSnackbar({
          text: 'Saved Successfully!',
          color: 'green',
          timeout: 1500
        })
        this.getCountries()
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    } else {
      this.showSnackbar({
        text: 'Please check all fields requirements',
        color: 'red',
        timeout: 2000
      })
    }
  }

  async updateDeminimis() {
    try {
      const payload_shipment_delete = {
        data: {
          data: [
          ]
        }
      }

      const payload_create = {
        data: []
      }

      const payload_edit = {
        data: []
      }

      var checkArr = [

        'deminimis_currency',
        'deminimis_limit',
        'shipping_term',
        'exclude_deminimis_category',
        'transit_point',
        'handling_charges'
      ]

      // set replace deminimis
      var deminimis_limit_to_string = JSON.stringify(this.countryDataDialog.deminimis_limit)
      this.countryDataDialog.deminimis_limit = deminimis_limit_to_string

      forEach(checkArr, arr => {
        if (arr === 'exclude_deminimis_category') {
          var idCategory = map(this.countryDataDialog[arr], 'id')
          var allIdCategory = [...this.currentCategories, ...idCategory]
          this.countryDataDialog[arr] = JSON.stringify(allIdCategory)
        }

        if (this.countryDataDialog['id_' + arr] === null) {
          if (arr === 'transit_point') {
            this.countryDataDialog[arr] =
            {
              type: lowerCase(this.countryDataDialog.transit_point_type),
              id: this.countryDataDialog.transit_point_id
            }
            this.countryDataDialog[arr] = JSON.stringify(this.countryDataDialog[arr])
          }
          var set_item_create = {
            attributes: {
              key: arr,
              country_id: this.countryDataDialog.id,
              source_country_id: null,
              value: this.countryDataDialog[arr]
            }
          }
          payload_create.data.push(set_item_create)
        } else {
          if (arr === 'transit_point') {
            this.countryDataDialog[arr] =
            {
              type: lowerCase(this.countryDataDialog.transit_point_type),
              id: this.countryDataDialog.transit_point_id
            }
            this.countryDataDialog[arr] = JSON.stringify(this.countryDataDialog[arr])
          }
          var set_item_update = {
            id: this.countryDataDialog['id_' + arr],
            attributes: {
              key: arr,
              country_id: this.countryDataDialog.id,
              source_country_id: null,
              value: this.countryDataDialog[arr]
            }
          }
          payload_edit.data.push(set_item_update)
        }

      })

      const responseCreate = await SettingsService.createSettings(payload_create)
      const responseEdit = await SettingsService.updateSettings(payload_edit)
      const responseDelete = await SettingsService.deleteSettings(payload_shipment_delete)
    } catch (error) {
      this.catchHandler(error)
    }
  }

  // Country Default Payment (Payment Channel)
  async getPaymentChannelsList() {
    try {
      const opts: any = {
        params: {
          'page[limit]': 10000
        }
      }
      const response = await PaymentChannelsService.getPaymentChannels()
      const responseData = response.data.data

      for (const dataPaymentChannels of responseData) {
        this.paymentChannelsList.push(dataPaymentChannels.attributes)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {

    }
  }

  async getRemarkStatus() {
    try {
      this.tableLoading = true

      const opts: any = {
        params: {
          'filter[status_type][is]': 2
        }
      }
      const response = await StatusService.getOrderStatus(opts)
      const responseData = response.data

      for (const dataRemarkStatus of responseData) {
        if (dataRemarkStatus.attributes.name != 'null') {
          const status = {
            name: startCase(dataRemarkStatus.attributes.name),
            id: dataRemarkStatus.attributes.id
          }
          this.remarkStatusList.push(status)
        }
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {

    }
  }

  async saveDefaultPaymentCountry() {
    try {
      const internationalValid = await this.$validator.validateAll('internationalPayment')
      const domesticValid = await this.$validator.validateAll('domesticPayment')

      if (internationalValid && domesticValid) {
        this.showLoading({ text: 'Saving...' })

        const payload = {
          country_id: this.countrySelected.id,
          international_shipping: {
            payment_channel_ids: this.internationalPayment.payment_channel_ids,
            instruction: this.internationalPayment.instruction,
            status_id: this.internationalPayment.status_id
          },
          domestic_shipping: {
            payment_channel_ids: this.domesticPayment.payment_channel_ids,
            instruction: this.domesticPayment.instruction,
            status_id: this.domesticPayment.status_id
          }
        }

        const response = await PaymentChannelsService.saveDefaultPaymentCountry(payload)

        if (response.data.success) {
          this.showSnackbar({
            text: 'Default Payment Country Successfully Added',
            color: 'green',
            timeout: 1500
          })
          this.closeLoading()
          this.closeDefaultPaymentCountryModal()
          this.defaultPaymentCountryModal = false
        } else {
          this.showSnackbar({
            text: 'Saved Failed',
            color: 'red',
            timeout: 1500
          })
        }
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {

    }
  }

  async openEditDefaultPayments(props) {
    const opts = {
      params: {
        'filter[country_id][is]': props.item.id
      }
    }
    const response = await PaymentChannelsService.getCountryPayments(opts)
    const responseData = response.data.data
    if (response) {
      forEach(responseData, data => {
        // 1 = International Shipping, 2 = Domestic Shipping
        if (data.attributes.shipping_type === 1) {
          this.internationalPayment.payment_channel_ids.push(data.attributes.payment_channel_id)
          this.internationalPayment.instruction = data.attributes.instruction
          this.internationalPayment.status_id = data.attributes.status_id
        } else {
          this.domesticPayment.payment_channel_ids.push(data.attributes.payment_channel_id)
          this.domesticPayment.instruction = data.attributes.instruction
          this.domesticPayment.status_id = data.attributes.status_id
        }
      })
    }
    this.countrySelected = {
      id: props.item.id,
      name: props.item.name
    }
    this.defaultPaymentCountryModal = true
  }

  closeDefaultPaymentCountryModal() {
    this.internationalPayment.payment_channel_ids = []
    this.internationalPayment.instruction = null
    this.internationalPayment.status_id = null
    this.domesticPayment.payment_channel_ids = []
    this.domesticPayment.instruction = null
    this.domesticPayment.status_id = null
    this.defaultPaymentCountryModal = false
    this.$validator.errors.clear()
    this.$validator.reset()
  }
}
